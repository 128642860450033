import React from 'react'
import { Box } from '@chakra-ui/react'
import { DealerTable } from './DealerTable.tsx'

export const DealerBox = () => {
    return (
        <Box className='section'>
            <h1 id='dealers' className='title'>
                ALL DEALERS
            </h1>
            <DealerTable />
        </Box>
    )
}