import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'

export const TreatmentBox = () => {
    return (
        <Box className='section'>
            <Box id='interview' className='title'>
                TREATMENT
            </Box>
            <Box className='interviewBox'>
                <Box
                    className='interviewPersonWrapper'
                >
                    <Image
                        src='/img/treatment_1.png'
                        fit='cover'
                        boxSize={[360, 360, 360, 300]}
                    />
                </Box>
                <Box style={{ minWidth: '70%' }} color={'white'} pl={[0, 10]} pr={[0, 10]}>
                    <Text fontSize={['xl', '2xl']}>
                        【トエル プロセストリートメント】<br />
                        toeL PROCESS TREATMENT
                    </Text>
                    <Text pt={5}>
                        <b>残留してしまったブリーチ剤を還元中和し、<br />
                            ヘアカラーの色ムラを防ぐ業務用トリートメント<br /><br />
                        </b>

                        ハイトーンカラーや透明感のあるブリーチオンカラーに必須な事前ブリーチ。<br />
                        しかし、ブリーチ剤に含まれる酸化剤がアフターシャンプーで<br />
                        取り除けずに残留してしまうと、その後のヘアカラーの反応が阻害され、<br />
                        色ムラになってしまいます。<br />
                        トエル プロセストリートメントは、残留してしまった酸化剤を還元中和し、<br />
                        毛髪全体を安定した状態に均一化させることで、<br />
                        ヘアカラーの色ムラやダメージの進行を抑えます。
                    </Text>
                </Box>
            </Box>
            <Box className='interviewBox'>
                <Box
                    className='interviewPersonWrapper'
                >
                    <Image
                        src='/img/treatment_2.jpg'
                        fit='cover'
                        boxSize={[360, 360, 360, 300]}
                    />
                    <Box>
                        <Box className='interviewNameWrapper lora'>西村 涼様</Box>
                        <Box className='interviewCompany lora'>
                            iLe. 代表
                        </Box>
                    </Box>
                </Box>
                <Box pl={[0, 10]} pr={[0, 10]} color={'white'}>
                    <Text fontSize={['xl', '2xl']}>
                        プロセストリートメント<br className='pcDisplayNone' />
                        導入店舗インタビュー
                    </Text>
                    <Text pt={5}>
                        ブリーチ後これをするのとしないのでは、オンカラーの切れ毛の量がかなりなくなります！<br />
                        色の発色も早くなりムラなどもなくなるのでおすすめです！<br />
                        薄い色はシャンプーで流れやすいですが、それもなくなります！<br /><br />
                        お客様からもプロセストリートメントを使うようになってから、<br />
                        家での実感も普段と違うらしく、「何かいつもと変えましたか？」と連絡がくるほど実感があるそうです！
                    </Text>
                </Box>
            </Box>
        </Box >
    )
}