import React from 'react'
import { Link, Image, Box, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom' // ルートをチェックするために useLocation をインポート
import { Burger } from './BurgerMenu.tsx'

export const Header = () => {
  const location = useLocation(); // 現在のパスを取得

  return (
    <Box>
      <Flex as="header" className='header'>
        {location.pathname === '/' && ( // トップページであるかどうかをチェック
          <Burger />
        )}
        <Link href='/'>
          <Image width="224px" height="auto" src='/img/logo.png' alt="Logo" pl={5} />
        </Link>
      </Flex>
    </Box>
  )
}
