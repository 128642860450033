import React from 'react'
import { Box } from '@chakra-ui/react'
import { FeatureBox } from './FeatureBox.tsx'
import { LineUpBoxSp } from './LineUpBoxSp.tsx'
import { LineUpBoxPc } from './LineUpBoxPc.tsx'
import { VideoBox } from './VideoBox.tsx'
import { BrandBox } from './BrandBox.tsx'
import { ColorChartBox } from './ColorChartBox.tsx'
import { InterviewBox } from './InterviewBox.tsx'
import { DealerBox } from './DealerBox.tsx'
import { FooterBox } from './FooterBox.tsx'
import { TreatmentBox } from './TreatmentBox.tsx'
import { NewRelease } from './NewRelease.tsx'
import { ColorRecipeBox } from './ColorRecipeBox.tsx'

type Props = {
  setIsOpen: any
  setImgPath: any
}

export const MainContent = (props: Props) => {
  return (
    <Box>
      {/* VIDEO */}
      <VideoBox device='pc' />
      {/* New Release */}
      <NewRelease />
      {/* BRAND CONCEPT */}
      <BrandBox />
      {/* FEATURE */}
      <FeatureBox />
      {/* LINE UP(SP) */}
      <LineUpBoxSp />
      {/* LINE UP(PC) */}
      <LineUpBoxPc />
      <ColorRecipeBox />
      <ColorChartBox setIsOpen={props.setIsOpen} setImgPath={props.setImgPath} />
      <TreatmentBox />
      <VideoBox device='sp' />
      <InterviewBox />
      <DealerBox />
      <FooterBox />
    </Box>
  )
}
