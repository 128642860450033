import React, { useState } from 'react'
import ModalWrapper from '../ui/component/ModalWrapper.tsx'
import { SideBar } from '../ui/component/SideBar.tsx'
import { TopContent } from '../ui/component/TopContent.tsx'
import { MainContent } from '../ui/component/MainContent.tsx'
import { Flex, Link } from '@chakra-ui/react'
import { Header } from '../ui/component/Header.tsx';

const Main = () => {
  const [imgPath, setImgPath] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  return (
    <main>
      <Link href='/style/main.css' rel='stylesheet' />
      <Link href='/style/pc.css' rel='stylesheet' />
      <Link href='/style/sp.css' rel='stylesheet' />
      <Header />
      <TopContent />
      <Flex>
        <SideBar />
        <MainContent setIsOpen={setIsOpen} setImgPath={setImgPath} />
        <SideBar />
      </Flex>

      {/* カラーチャートのクリック時 */}
      <ModalWrapper
        imgPath={imgPath}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </main>
  )
}

export default Main;
