import React from 'react';
import RecipeComponent from '../ui/colorComponent/ColorRecipeGallery.tsx'
import { FooterBox } from '../ui/component/FooterBox.tsx'
import { Header } from '../ui/component/Header.tsx';

const ColorRecipe = () => {
  return (
    <main>
      <Header />
      <RecipeComponent />
      <FooterBox />
    </main>
  )
};

export default ColorRecipe;
