type Dealer = {
  region: string;
  name: string;
  address: string;
  tel: string;
  web: string;
  map: string
};

export const dealers: Dealer[] = [
  { region: "全国広域", name: "株式会社 きくや美粧堂", address: "東京都渋谷区恵比寿西1-13-7 KB’Sアーチビル", tel: "03-3748-0200", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/XgpDVD5c2MYzkuT88" },
  { region: "全国広域", name: "株式会社 タチカワ", address: "新潟県新潟市東区大形本町6-8-14", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/vYD5L7DyiboXWzFbA" },
  { region: "全国広域", name: "株式会社 リーディアル", address: "大阪府大阪市淀川区西中島5-11-9 新大阪中里ビル9F", tel: "06-6195-9622", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/3PFhMrxcTvugTDS99" },
  { region: "北海道", name: "株式会社 アポロ商事 札幌支店", address: "北海道札幌市豊平区平岸4条7丁目11-21", tel: "011-817-0211", web: "https://apolloshoji.co.jp", map: "https://maps.app.goo.gl/vi4JVNP5RUiNPJGD6" },
  { region: "北海道", name: "株式会社 きくや美粧堂 札幌支店", address: "北海道札幌市中央区南1条西7丁目1-3 アルファ南1条ビル", tel: "011-280-2033", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/6MCQbG5J5bEAN23N7" },
  { region: "北海道", name: "株式会社 小林かもじ店 本社", address: "北海道旭川市5条通7丁目買物公園", tel: "0166-22-6161", web: "http://www.kamoji.co.jp/", map: "https://goo.gl/maps/dRwTwahY9MCsLGdY9" },
  { region: "北海道", name: "株式会社 小林かもじ店 札幌オフィス", address: "北海道札幌市中央区北4条西16丁目 テルウェル第2ビル5F", tel: "011-622-7722", web: "https://www.kamoji.co.jp/", map: "https://goo.gl/maps/dsqk36w3yB9b6yRRA" },
  { region: "北海道", name: "株式会社 Saitou", address: "北海道札幌市西区西町北2丁目3-6", tel: "011-777-6611", web: "http://www.saitou2014.com/", map: "https://goo.gl/maps/VuXeae8pGQN9mAQj9" },
  { region: "北海道", name: "佐藤美容商事", address: "北海道札幌市白石区川下2614", tel: "011-873-3106", web: "", map: "https://goo.gl/maps/ifwjyD5yYL7hguQf9" },
  { region: "北海道", name: "株式会社 トピー商事 札幌営業所", address: "北海道札幌市白石区米里1条2丁目4-1 コウエイビル1F", tel: "011-873-2820", web: "http://www.topy-web.com/", map: "https://goo.gl/maps/Whg7gS9iUreJNZek7" },
  { region: "北海道", name: "株式会社 トピー商事 函館営業所", address: "北海道函館市本通2丁目23-3", tel: "0138-55-7741", web: "http://www.topy-web.com/", map: "https://goo.gl/maps/2RbMWMAHY4sq45PMA" },
  { region: "北海道", name: "株式会社 光美容化学 北海道地区本部", address: "北海道札幌市白石区北郷二条1-4-6", tel: "011-598-8801", web: "http://www.hikari-b.co.jp/", map: "https://goo.gl/maps/MQwBnRaUfbqkBuqT7" },
  { region: "北海道", name: "有限会社 北栄美容商事", address: "北海道旭川市東光2条6丁目3-18", tel: "0166-34-1835", web: "", map: "https://goo.gl/maps/xDZWza7X2aNmp4Q36" },
  { region: "北海道", name: "株式会社 前田美粧", address: "北海道釧路市豊川町18-1", tel: "0154-24-7222", web: "", map: "https://goo.gl/maps/KmhrJwMQnyDszCMS7" },
  { region: "東北", name: "株式会社 アポロ商事", address: "宮城県仙台市宮城野区中野字神明156-3", tel: "022-352-5571", web: "https://apolloshoji.co.jp", map: "https://goo.gl/maps/xy3tC1q13AnDPWyq5" },
  { region: "東北", name: "有限会社 天野商事", address: "岩手県奥州市水沢区吉小路 44-5", tel: "0197-23-6192", web: "", map: "https://goo.gl/maps/6qYkB4PLkRE5RemG9" },
  { region: "東北", name: "株式会社 きくや美粧堂 仙台支店", address: "宮城県仙台市宮城野区二十人町302-11 108駅東ビル5F", tel: "022-292-5272", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/mzU6gYo4xWCN9kRu6" },
  { region: "東北", name: "株式会社 きくや美粧堂 郡山支店", address: "福島県郡山市中町14-26 ロードン中央通りビル1F", tel: "024-927-1350", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/bBoww5AZY5sUvPTr9" },
  { region: "東北", name: "株式会社 タチカワ 秋田支店", address: "秋田県秋田市東通1-11-7", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/y9WzEBHUXwvVb56n9" },
  { region: "東北", name: "株式会社 タチカワ 仙台支店", address: "宮城県仙台市青葉区中央2丁目2番10号 仙都会館2F", tel: "022-208-9100", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/33JX8igygi3c3Tjg7" },
  { region: "東北", name: "株式会社 タチカワ 福島支店", address: "福島県会津若松市花春町2-34", tel: "0242-88-7766", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/me2cVnbLMDrbiTd97" },
  { region: "東北", name: "株式会社 瀬戸商会", address: "福島県郡山市安積3丁目12", tel: "024-937-1233", web: "https://www.seto-beauty.co.jp", map: "https://goo.gl/maps/UQw4XcP4kJAXDweA7" },
  { region: "東北", name: "株式会社 光美容化学 本社", address: "青森県弘前市代官町21", tel: "0172-36-1234", web: "http://www.hikari-b.co.jp/", map: "https://goo.gl/maps/1o2pyknK3vNgpCHeA" },
  { region: "東北", name: "株式会社 ビューコム", address: "宮城県仙台市太白区富沢南2丁目20-6", tel: "022-226-8897", web: "", map: "https://goo.gl/maps/nVfKSPpQRXoE5MJt9" },
  { region: "東北", name: "株式会社 rooc", address: "秋田県大仙市下鴬野字下中嶋251-6", tel: "0187-54-2106", web: "", map: "https://goo.gl/maps/xRrStDwyaHomM1fB8" },
  { region: "関東", name: "株式会社 アドバコーポレーション北関東", address: "栃木県宇津宮市宝井町331-3", tel: "028-611-1812", web: "https://www.adva.jp", map: "https://goo.gl/maps/aBQc6qAC77UkPUvNA" },
  { region: "関東", name: "株式会社 エバタ美容商事", address: "茨城県水戸市西原2丁目16-24", tel: "029-251-5831", web: "https://www.ebata-beauty.com", map: "https://goo.gl/maps/iwnJff5PM2CB4CKe8" },
  { region: "関東", name: "株式会社 ヴァン", address: "神奈川県平塚市四之宮1-7-4", tel: "0463-33-7111", web: "https://vent.co.jp", map: "https://goo.gl/maps/jtpk6eHSiu5yDWtBA" },
  { region: "関東", name: "株式会社 きくや美粧堂 中央第一支店", address: "東京都大田区上池台5-5-5", tel: "03-3748-0200", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/vXZkaputMDgr6MUR9" },
  { region: "関東", name: "株式会社 きくや美粧堂 青山支店", address: "東京都港区北青山3-5-17 KNKビル1F", tel: "03-5772-6226", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/3vJWZvkjazQscCZQ6" },
  { region: "関東", name: "株式会社 きくや美粧堂 池袋支店", address: "東京都豊島区南池袋2-29-9 損保ジャパン南池袋ビル2F", tel: "03-5957-7117", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/iR4DckJNU3FLWTk98" },
  { region: "関東", name: "株式会社 きくや美粧堂 浅草支店", address: "東京都台東区西浅草2-27-7 浅草TFビル3F", tel: "03-5826-5111", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/v4jU2cLZQFGwhXQLA" },
  { region: "関東", name: "株式会社 きくや美粧堂 吉祥寺支店", address: "東京都武蔵野市吉祥寺本町2-5-10 いちご吉祥寺ビル2F", tel: "0422-23-6111", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/mRAHdbAdhFmg8YvMA" },
  { region: "関東", name: "株式会社 きくや美粧堂 八王子支店", address: "東京都八王子市明神町4-5-3 橋捷ビル5F", tel: "042-631-6077", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/KjcnosceTeJx1HHZ8" },
  { region: "関東", name: "株式会社 きくや美粧堂 横浜支店", address: "神奈川県横浜市西区北幸2-10-36 KDX横浜西口ビル9F", tel: "045-311-6530", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/t5MxWnB8XW8rD4tX9" },
  { region: "関東", name: "株式会社 きくや美粧堂 横浜ANNEX", address: "神奈川県横浜市西区北幸2-9-14 相鉄本社ビル1F", tel: "045-322-3050", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/Ps82rhYzR1f5q6fR9" },
  { region: "関東", name: "株式会社 きくや美粧堂 湘南支店", address: "神奈川県藤沢市藤沢484-12 セントラルビルディング1F", tel: "0466-23-1766", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/GPkAQYg6Z4PV858D9" },
  { region: "関東", name: "株式会社 きくや美粧堂 大和支店", address: "神奈川県大和市中央林間3-5-11 野崎ビル3F", tel: "046-271-6663", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/FNwp5B52VrrmYiTA7" },
  { region: "関東", name: "株式会社 きくや美粧堂 さいたま支店", address: "埼玉県さいたま市中央区本町東3-10-6", tel: "048-851-6780", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/JcZvRJ1DFM2NvqXe7" },
  { region: "関東", name: "株式会社 きくや美粧堂 川越支店", address: "埼玉県川越市富士見町1-1 LTビル1-2F", tel: "049-229-0033", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/rEADh3DnZXgNT5KC6" },
  { region: "関東", name: "株式会社 きくや美粧堂 千葉支店", address: "千葉県船橋市東船橋3-39-15", tel: "047-420-6888", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/FxqaXxzk8jyBWmEw7" },
  { region: "関東", name: "株式会社 きくや美粧堂 水戸支店", address: "茨城県水戸市笠原町1525", tel: "029-305-6300", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/wLKtQa8KXp7eNG776" },
  { region: "関東", name: "株式会社 きくや美粧堂 足利支店", address: "栃木県足利市問屋町1184-5", tel: "0284-74-2016", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/LZZVHGrvHCQqzxjx6" },
  { region: "関東", name: "株式会社 きくや美粧堂 宇都宮支店", address: "栃木県宇都宮市西川田町224-1", tel: "028-615-0007", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/YpMiE4beX1aDEQbe8" },
  { region: "関東", name: "株式会社 きくや美粧堂 町田支店", address: "東京都町田市森野1-31-7 エイティービル4F", tel: "042-729-2133", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://maps.app.goo.gl/YwqAinsvjyK7Pxfk7" },
  { region: "関東", name: "株式会社 タチカワ 東京支店", address: "東京都港区芝3丁目3-12 松下ビル2F", tel: "03-6436-5432", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/6fAXuVoHepc8kLut9" },
  { region: "関東", name: "ミツイコーポレーション株式会社 東京支社", address: "東京都品川区東品川2-2-20 天王洲オーシャンスクエア14F", tel: "03-6433-3240", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/3DJ8xDZW7irJejUt5" },
  { region: "関東", name: "ミツイコーポレーション株式会社 横浜支社", address: "神奈川県横浜市西区みなとみらい3丁目6-1 みなとみらいセンタービル3階", tel: "045-680-0022", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/yuNBvNbpNqiUaoTr8" },
  { region: "中部", name: "株式会社 あい美す", address: "愛知県あま市篠田字南大門26-3", tel: "052-462-6626", web: "http://www.aibisu.com/", map: "https://goo.gl/maps/YgPWcrsoDyxW5P2i6" },
  { region: "中部", name: "株式会社 アドバコーポレーション東海 静岡営業所", address: "静岡県静岡市葵区柚木250", tel: "054-294-7597", web: "https://www.adva.jp", map: "https://goo.gl/maps/oiv27Ur3kjM8RsP7A" },
  { region: "中部", name: "株式会社 アドバコーポレーション東海 浜松営業所", address: "静岡県浜松市中区中央3丁目2-20", tel: "053-455-0500", web: "https://www.adva.jp", map: "https://goo.gl/maps/Ccey273ofwmAPX2r7" },
  { region: "中部", name: "株式会社 イトヒラ商会", address: "岐阜県岐阜市若宮町4-10", tel: "058-263-1701", web: "", map: "https://goo.gl/maps/n9pdwf4s22pGF1RUA" },
  { region: "中部", name: "AQS", address: "静岡県静岡市駿河区登呂5-14-24", tel: "054-287-3450", web: "", map: "https://goo.gl/maps/TRhFHuL2LRcUBy9e8" },
  { region: "中部", name: "株式会社 エムライン", address: "石川県金沢市桜田町2-113-101", tel: "076-256-1000", web: "", map: "https://goo.gl/maps/BXz7p2CrXCV5VNKz8" },
  { region: "中部", name: "株式会社 きくや美粧堂 静岡支店", address: "静岡県静岡市葵区南安倍1-6-10", tel: "054-653-2332", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/7fCcEHfRZaFdsXeK6" },
  { region: "中部", name: "株式会社 きくや美粧堂 沼津支店", address: "静岡県沼津市西条町161 カーニープレイス沼津ビル4F402", tel: "055-954-1977", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/Jh61weUtPwTzug6NA" },
  { region: "中部", name: "株式会社 きくや美粧堂 浜松オフィス", address: "静岡県浜松市中区砂山町323-16 ヴェルメゾン浜松2F3号", tel: "053-413-7722", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/QGZXSPCbCyh4semx7" },
  { region: "中部", name: "株式会社 きくや美粧堂 新潟支店", address: "新潟県新潟市中央区紫竹山2-4-43 渡辺ビルB棟2F", tel: "025-240-1177", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/2h4VtLsrgRCRfNmw8" },
  { region: "中部", name: "株式会社 きくや美粧堂 上越支店", address: "新潟県上越市下門前1869", tel: "025-531-0451", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/Vif1LKNdn5dex4zV8" },
  { region: "中部", name: "株式会社 きくや美粧堂 岐阜支店", address: "岐阜県岐阜市元町3-11", tel: "058-264-1761", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/f3QnGL2YQrLzg29A9" },
  { region: "中部", name: "株式会社 きくや美粧堂 金沢支店", address: "石川県金沢市駅西新町3-16-27", tel: "076-234-3601", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/LEuM4uko2spebid86" },
  { region: "中部", name: "株式会社 きくや美粧堂 名古屋支店", address: "愛知県名古屋市中村区名駅3-9-6 アルティメイト名駅2nd 1F", tel: "052-269-3331", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://maps.app.goo.gl/sEHumogkxQbk2Eih9" },
  { region: "中部", name: "株式会社 きくや美粧堂 豊橋支店", address: "愛知県豊橋市弥生町東豊和149-4", tel: "0532-81-0228", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/ofPFvVh9uEFCNbqD8" },
  { region: "中部", name: "株式会社 シャルズ", address: "静岡県駿東郡長泉町中土狩570-1", tel: "055-988-0111", web: "", map: "https://goo.gl/maps/3PVrVoKdNzopbex6A" },
  { region: "中部", name: "株式会社 タチカワ 新潟支店", address: "新潟県新潟市東区大形本町6-8-14", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/7oh2tyGnrvUDLVdV6" },
  { region: "中部", name: "株式会社 タチカワ 佐渡営業所", address: "新潟県佐渡市秋津956", tel: "0259-27-6563", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/Ph5hoy9u55iVKs4s7" },
  { region: "中部", name: "株式会社 タチカワ 長岡支店", address: "新潟県長岡市城内町3-6-4", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/eUdzQA3WCCzVS59J7" },
  { region: "中部", name: "株式会社 タチカワ 上越支店", address: "新潟県上越市藤新田2-4-3", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/Vx2j5SmaQhPYJCnB7" },
  { region: "中部", name: "株式会社 タチカワ 松本営業所", address: "長野県松本市井川城3-1-8", tel: "025-275-6666", web: "https://www.jbl-tachikawa.co.jp", map: "https://goo.gl/maps/dvos4uk2hn9mYMkX6" },
  { region: "中部", name: "株式会社 パブリック美容商事", address: "愛知県豊橋市東田町248-3", tel: "0532-62-7445", web: "", map: "https://goo.gl/maps/h3S1EeSpdBoH5QAv9" },
  { region: "中部", name: "株式会社 ビオサプライ", address: "愛知県名古屋市天白区元八事5丁目13", tel: "052-833-0616", web: "", map: "https://goo.gl/maps/SFNxQyohm2bEtFfT6" },
  { region: "中部", name: "有限会社 フェイス", address: "石川県金沢市光が丘3-204", tel: "076-298-5522", web: "https://faith-beauty.jp", map: "https://goo.gl/maps/d3S9dRK41FKXqYSA7" },
  { region: "中部", name: "株式会社 光本美容商事社", address: "三重県四日市市中部17-7", tel: "059-352-3151", web: "https://mitsumoto-bs.jp", map: "https://goo.gl/maps/F2Sjo5ytNqEaoxr7A" },
  { region: "中部", name: "株式会社 モリタ", address: "富山県射水市手崎472", tel: "0766-55-1515", web: "https://b-morita.com/", map: "https://goo.gl/maps/z2AmjNtSBJTrHEqp8" },
  { region: "中部", name: "株式会社 リーディアル 福井営業所", address: "福井県福井市和田東1-2216", tel: "0776-22-2021", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/nau3cgu3413m974NA" },
  { region: "中部", name: "株式会社 リズム", address: "愛知県豊田市堤町宮崎8", tel: "0565-52-3046", web: "https://rizumu.co.jp/", map: "https://goo.gl/maps/wenvFG7MywX9A4E7A" },
  { region: "中部", name: "有限会社 リッチ美容商事", address: "愛知県清須市西市場6丁目11-30", tel: "052-400-7977", web: "", map: "https://goo.gl/maps/HNxtu8PkRUi8G6DW8" },
  { region: "中部", name: "ミツイコーポレーション株式会社 静岡本社", address: "静岡県静岡市葵区千代田6丁目16-2", tel: "054-261-3185", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/FD1pTNoS9szuLqfY8" },
  { region: "中部", name: "ミツイコーポレーション株式会社 浜松支社", address: "静岡県浜松市東区和田町834-1", tel: "053-460-8100", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/P2vYtaSz2sLqcp1o6" },
  { region: "中部", name: "ミツイコーポレーション株式会社 三島支社", address: "静岡県駿東郡清水町堂庭242-8", tel: "055-981-0505", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/rTRqTLsZdwg5bJEn6" },
  { region: "中部", name: "ミツイコーポレーション株式会社 豊橋支社", address: "愛知県豊橋市佐藤2丁目11-1", tel: "0532-69-3636", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/rgx5QtUm2NLppisZ7" },
  { region: "中部", name: "ミツイコーポレーション株式会社 名古屋支社", address: "愛知県名古屋市千種区神田町8-3", tel: "052-719-7800", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/aaqWnhHt3ZhTfDCQ7" },
  { region: "中部", name: "ミツイコーポレーション株式会社 栄支社", address: "愛知県名古屋市中区栄3-7-9 新鏡栄ビル4F", tel: "052-265-8300", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/TmQmtmXkeRXYQbbV7" },
  { region: "中部", name: "ミツイコーポレーション株式会社 金沢支社", address: "石川県金沢市玉鉾二丁目252", tel: "076-255-3702", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/YyxV1fnKLvb56vD18" },
  { region: "中部", name: "ミツイコーポレーション株式会社 松本支社", address: "長野県松本市中央2丁目3-13 サードミレニアムゲート3階", tel: "0263-38-3360", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/MuhSwZVYXaGawfFq6" },
  { region: "中部", name: "株式会社ルフト 本社", address: "愛知県名古屋市中区千代田3丁目18-24", tel: "052−332−2577", web: "https://project-luft.com/", map: "https://maps.app.goo.gl/g22SWCJdCQmcgy198" },
  { region: "中部", name: "株式会社ルフト 浜松支社", address: "静岡県浜松市中区西浅田2丁目2-18 ビジネスパーク西浅田Ｃ棟16号", tel: "053-401-2270", web: "https://project-luft.com/", map: "https://maps.app.goo.gl/TotSQyZcezJXr3Vd6" },
  { region: "近畿", name: "みたか株式会社", address: "兵庫県伊丹市南野北4丁目3-1", tel: "072-779-3578", web: "", map: "https://maps.app.goo.gl/p7jg7k9xS4bL4Poi9" },
  { region: "近畿", name: "株式会社 アドバコーポレーション 和歌山営業所", address: "和歌山県和歌山市冬野426-1", tel: "073-460-1590", web: "https://www.adva.jp", map: "https://goo.gl/maps/yN8AvbaMh8YAvxT7A" },
  { region: "近畿", name: "株式会社 きくや美粧堂 大阪支店", address: "大阪府大阪市中央区北久宝寺町4-2-6 2F", tel: "06-4963-8155", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/hgjyMhoSNkrFeqRz6" },
  { region: "近畿", name: "株式会社 きくや美粧堂 京都支店", address: "京都府京都市中京区烏丸通四条上ル笋町688 第15長谷ビル7F", tel: "075-254-8844", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/g5cYdTJ4jGXK7Aya7" },
  { region: "近畿", name: "株式会社 きくや美粧堂 姫路支店", address: "兵庫県姫路市東延末3-8 アートスペースビル", tel: "079-286-5101", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/axWVtYGkyxKYfChs5" },
  { region: "近畿", name: "株式会社 千代田", address: "大阪府堺市北区金岡町2803-6", tel: "072-257-6000", web: "https://www.chiyodabs.com", map: "https://goo.gl/maps/E4t5K7VKHaYjeNRc9" },
  { region: "近畿", name: "有限会社 プライダー", address: "大阪府大阪市旭区大宮3-2-5", tel: "06-6958-3651", web: "https://www.prider.com/", map: "https://maps.app.goo.gl/VaiNinpkuemaBBmr9" },
  { region: "近畿", name: "株式会社 リーディアル 本社営業所", address: "大阪府大阪市淀川区野中南1-12-39", tel: "06-6307-2101", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/f1WtPJ4bsiacoWP7A" },
  { region: "近畿", name: "株式会社 リーディアル 堺営業所", address: "大阪府堺市堺区北丸保園1-3", tel: "072-232-5900", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/V29XrRdx7fzx3Azv8" },
  { region: "近畿", name: "株式会社 リーディアル 和歌山営業所", address: "和歌山県紀の川市粉河131-1", tel: "0736-73-5923", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/suwfiyBzNCLrUo787" },
  { region: "近畿", name: "株式会社 リーディアル 神姫営業所", address: "兵庫県神戸市中央区磯辺通2-2-3", tel: "078-291-5216", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/9WsQQxTx1ishVLfy9" },
  { region: "近畿", name: "株式会社 リーディアル 豊岡営業所", address: "兵庫県豊岡市幸町6-29", tel: "0796-22-2918", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/yKsMPwQPD7pZkUaSA" },
  { region: "近畿", name: "株式会社 リーディアル 福知山営業所", address: "京都府福知山市野花809", tel: "0773-33-5070", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/onfQ6CtWeLpLbuqTA" },
  { region: "近畿", name: "株式会社 リーディアル 奈良営業所", address: "奈良県奈良市芝辻町4-9-2-101", tel: "0742-30-0551", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/x8JV8eWghQ71DANE9" },
  { region: "近畿", name: "株式会社 リーディアル 京滋営業所", address: "滋賀県大津市大萱3-14-2 ブレジオ1F", tel: "077-574-7428", web: "https://ledeal.co.jp", map: "https://maps.app.goo.gl/VNeRz8ZPatxpaRCA6" },
  { region: "近畿", name: "ミツイコーポレーション株式会社 大阪支社", address: "大阪府大阪市中央区心斎橋筋2丁目7-18 プライムスクエア心斎橋4階", tel: "06-4963-3615", web: "https://www.mitsui-corp.co.jp/", map: "https://maps.app.goo.gl/6rt3VrtjEzDNEcui9" },
  { region: "近畿", name: "株式会社ルフト 関西営業所", address: "大阪府高槻市竹之内町68 クランベール南高槻201", tel: "", web: "https://project-luft.com/", map: "https://maps.app.goo.gl/p1Uc8LQrdgu4P6o47" },
  { region: "中国・四国", name: "株式会社 アドバコーポレーション 本社", address: "広島県広島市南区段原1丁目4-19", tel: "082-506-2830", web: "https://www.adva.jp", map: "https://maps.app.goo.gl/5PHV2C68huvPqARs8" },
  { region: "中国・四国", name: "株式会社 アドバコーポレーション 福山営業所", address: "広島県福山市野上町3丁目1-31", tel: "084-924-8818", web: "https://www.adva.jp", map: "https://goo.gl/maps/X5fC2irZKHiAvgvF7" },
  { region: "中国・四国", name: "株式会社 アドバコーポレーション 広島営業所", address: "広島県広島市南区段原1丁目4-19 アドバビル4F", tel: "082-506-0562", web: "https://www.adva.jp", map: "https://goo.gl/maps/JeJfb5Bk4TTzHVgCA" },
  { region: "中国・四国", name: "株式会社 きくや美粧堂 岡山支店", address: "岡山県岡山市北区柳町1-5-25 ArcStage柳町1F", tel: "086-227-2255", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/jYQPX22JkvkpYKXE8" },
  { region: "中国・四国", name: "株式会社 きくや美粧堂 広島支店", address: "広島県広島市中区三川町2-5 Glanz三川町6F", tel: "082-545-0771", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/NiZHCzJ6BVW9ZCTy8" },
  { region: "中国・四国", name: "有限会社 コスモ美容商会", address: "愛媛県松山市山西町829-2", tel: "089-952-3358", web: "", map: "https://goo.gl/maps/yHpw8LNPHwvQexis9" },
  { region: "中国・四国", name: "株式会社 貞金商店", address: "山口県下関市阿弥陀寺町6-22", tel: "083-231-5617", web: "https://www.sadakane622.co.jp/", map: "https://maps.app.goo.gl/Yo1oCvsGpxjp2m6t8" },
  { region: "中国・四国", name: "三興物産販売 株式会社", address: "山口県宇部市若松町3−39", tel: "0836-31-5300", web: "http://sankobussan.jp/", map: "https://goo.gl/maps/FpUicxSwtRAGJoBJ6" },
  { region: "中国・四国", name: "株式会社 ジャックス・インク", address: "徳島県徳島市八万町大坪257-9", tel: "088-668-0756", web: "https://www.jacks-inc.co/", map: "https://goo.gl/maps/US7rsXdeKa7xTNhPA" },
  { region: "中国・四国", name: "トピックスター 株式会社", address: "愛媛県松山市和気町1丁目423-1 曽田ハイツ204号", tel: "089-904-6370", web: "https://topic-star.info", map: "https://goo.gl/maps/HwXkcojoXPt3iegx5" },
  { region: "中国・四国", name: "正守産業 株式会社", address: "広島県広島市中区鶴見町6-2", tel: "082-243-2211", web: "https://sites.google.com/view/masamori/", map: "https://maps.app.goo.gl/mxgd5GSmmP4erS2u8" },
  { region: "中国・四国", name: "株式会社 リーディアル 鳥取営業所", address: "鳥取県鳥取市瓦町172 サンテラス101号", tel: "0857-29-5160", web: "https://ledeal.co.jp", map: "https://goo.gl/maps/au8CApVqhS3SWv3L9" },
  { region: "九州・沖縄", name: "株式会社 アドバコーポレーション九州", address: "福岡県久留米市南2-21-66-101", tel: "0942-65-8834", web: "https://www.adva.jp", map: "https://maps.app.goo.gl/kyHmPWGb4zrJmyut6" },
  { region: "九州・沖縄", name: "有限会社 Ｍロード・ミヨシヤ", address: "鹿児島県鹿児島市甲突町15-9", tel: "099-295-4411", web: "", map: "https://goo.gl/maps/hUzfw9FinVVRS1L86" },
  { region: "九州・沖縄", name: "ＯＲＩＨＳ.company", address: "鹿児島県鹿児島市稲荷町34-8 2F", tel: "099-295-3735", web: "", map: "https://goo.gl/maps/svzcQDWLR9VtvK4T8" },
  { region: "九州・沖縄", name: "株式会社 きくや美粧堂 福岡支店", address: "福岡県福岡市中央区大名1-14-8 Barbizon93 4F", tel: "092-737-3327", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/EDcbMZ8ASjXMyDGV7" },
  { region: "九州・沖縄", name: "株式会社 きくや美粧堂 熊本支店", address: "熊本県熊本市中央区上通町7-2 モアーズビル上通3F", tel: "096-319-3003", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/E12MxmFqETrRNCvJ7" },
  { region: "九州・沖縄", name: "株式会社 きくや美粧堂 鹿児島支店", address: "鹿児島県鹿児島市東千石町18-15 NCガイドショップ会館2F", tel: "099-219-7555", web: "https://www.kikuya-bisyodo.co.jp/about", map: "https://goo.gl/maps/6BERpoF7w7JxuYwW8" },
  { region: "九州・沖縄", name: "株式会社 サンライズ宮崎", address: "宮崎県宮崎市佐土原町下田島20113-16", tel: "0985-41-4522", web: "", map: "https://goo.gl/maps/7jVuGBgUEX3o8bzFA" },
  { region: "九州・沖縄", name: "有限会社 尚美堂", address: "鹿児島県奄美市名瀬小浜町23-3", tel: "0997-52-2263", web: "", map: "https://goo.gl/maps/LW9boPbLRLfXgmZT7" },
  { region: "九州・沖縄", name: "株式会社 新垣忠商事", address: "沖縄県浦添市牧港3-38-2", tel: "098-876-1655", web: "http://www.next-innovation-bs.com/", map: "https://goo.gl/maps/8V6uA8XU2yCSXCrJ7" },
  { region: "九州・沖縄", name: "タカハシ 株式会社", address: "福岡県福岡市中央区平尾5-5-37", tel: "092-531-6531", web: "https://www.takahashi-corporation.jp", map: "https://goo.gl/maps/HZUhSoAkbc3D45ik9" },
  { region: "九州・沖縄", name: "株式会社 NEXT INNOVATION", address: "鹿児島県霧島市隼人町見次1300番地2ドラゴンポート88 10B-1号室", tel: "0995-55-8873", web: "http://www.next-innovation-bs.com/", map: "https://goo.gl/maps/1mFm4PGDS6K1Yorp7" },
  { region: "九州・沖縄", name: "有限会社 B・アート", address: "熊本県熊本市北区楡木2丁目11-97", tel: "096-338-3433", web: "", map: "https://goo.gl/maps/iRAZB2iiWXHLq1LJ7" },
  { region: "九州・沖縄", name: "ベニフィット", address: "熊本県熊本市東区御領2-1-1", tel: "096-388-9235", web: "http://www.benefit-bm.com/", map: "https://goo.gl/maps/JvV2gGgLgA2XgQGP9" },
  { region: "九州・沖縄", name: "株式会社 MAEDA", address: "熊本県熊本市中央区帯山3-18-51", tel: "096-285-3894", web: "http://www.maeda-biyou.co.jp", map: "https://goo.gl/maps/gAPmQf1HaGiUtQmf7" },
  { region: "九州・沖縄", name: "ライブラビューティーブレーン", address: "福岡県直方市感田2418-1", tel: "0949-36-5097", web: "", map: "https://goo.gl/maps/LuGxbR6bscGDrNe7A" },
];
