export interface ColorRecipe {
  id: number;
  styleName: string;
  salonName: string;
  stylistName: string;
  colorCategoryId: number;
  before: string;
  after: string;
  colorRecipe: string;
  description: string;
  remark?: string;
  instagramUrl: string;
}

const colorRecipes: ColorRecipe[] = [
  {
    id: 27,
    styleName: 'ミライドン',
    colorCategoryId: 1,
    salonName: "ALLY TOKYO",
    before: 'ベース19トーン',
    after: '10トーン',
    colorRecipe: 'purpleの部分　V/10 + P/10 + e:Purple + clear 1:10%: 10%:2 3% 2倍<br />水色の部分　e:cyan10:clear 1:3 3%等倍<br />黒の部分　e:A/3 3%等倍',
    description: 'Beforeは19トーンベースに元々顔まわりに黒のボーダーデザインが少し色褪せていたので追加で色をチャージしました。<br />ベースが綺麗だったのでリタッチだけして抜きっぱなしのまま活かしつつスライシングでデザインをランダムに追加しました。',
    stylistName: 'ヤマダナナミ',
    instagramUrl: 'https://www.instagram.com/nanami__hair/',
  },
  {
    id: 1,
    styleName: 'ダークネイビー',
    salonName: 'ALLY TOKYO',
    stylistName: 'alipee',
    colorCategoryId: 1,
    before: '根本18トーン、毛先10トーン',
    after: '8トーン',
    colorRecipe: '根本V/8 +CL 1:2 3%2倍<br />中間毛先ek:Navy 3% 等倍',
    description: 'beforeの毛先に強い赤残留がデザインで残っていたのでネイビーで強く打ち消し、発色。トップはブリーチベースを薄く紫みでホワイト調整。雰囲気を強く出した。',
    remark: '',
    instagramUrl: 'https://www.instagram.com/alipee6/',
  },
  {
    id: 2,
    styleName: 'シルキーベージュ',
    salonName: "COA 銀座",
    stylistName: 'YUDAI',
    colorCategoryId: 2,
    before: '全体11トーン、インナー15トーン',
    after: '7トーン',
    colorRecipe: 'Mt/6+Be/6+M/8 1:1:10% 4.5%<br />インナー Be/8+Mt/8 1:1 3%',
    description: 'かなり強いオレンジ味の強いbeforeを柔らかく補正。モデルさんの魅力を最大限引き出す透明感と艶感のあるカラーデザイン。',
    remark: '',
    instagramUrl: 'https://www.instagram.com/yudai_bob/',
  },
  {
    id: 3,
    styleName: 'ピンクベージュ',
    colorCategoryId: 4,
    salonName: 'ZYNX渋谷',
    before: '全体14トーン',
    after: '10トーン',
    colorRecipe: 'PBe/8+P/8 (1:1)+CL2倍 3%<br />毛先 e:Pink',
    description: 'ビフォーはオレンジ系のベージュでそのままの色味を活かして毛先にアクセントでピンクを入れ、全体的に明るい印象にした',
    stylistName: 'TAKUMA',
    instagramUrl: 'https://www.instagram.com/takuma_aka_zynx?igsh=bmo2ZjgyY2R6dzV6&utm_source=qr',
  },
  {
    id: 16,
    styleName: 'エフェクトシルバー',
    salonName: 'Afinar YOKOHAMA',
    stylistName: 'HIRO',
    colorCategoryId: 3,
    before: '17トーン',
    after: '17トーン',
    colorRecipe: "Mt/8+Be/8+V8+e:Pink (1:1:2:5%)+CL5倍 3%",
    description: 'ブリーチで綺麗に18トーンまでリフトしクリアなホワイトシルバーを作るために基材を５倍で薄め塗布',
    remark: '',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 4,
    styleName: 'チェリーレッド',
    salonName: 'Tiam shibuya',
    stylistName: 'NAO',
    colorCategoryId: 4,
    before: '16トーン',
    after: '10トーン',
    colorRecipe: 'R/6+V/6+e:RED 2:1:20% 3%',
    description: 'エフェクトレッドを使い、赤発色を強調！！個性の強いレッドを表現しています。',
    remark: '',
    instagramUrl: 'https://www.instagram.com/_nao_tiam/',
  },
  {
    id: 5,
    styleName: 'シフォンベージュ',
    salonName: 'Luxo by Afinar 町田店',
    stylistName: 'HAYATO',
    colorCategoryId: 2,
    before: '13トーン',
    after: '10トーン',
    colorRecipe: 'Mt/10+P/10+e:Purple 2:1:5% 4.5%',
    description: 'ハイライト履歴のモデルさんに黄ばみを柔らかく抑え初夏らしい深みと抜け感のあるカラーに。',
    remark: '',
    instagramUrl: 'https://www.instagram.com/h_y__to_afinar/',
  },
  {
    id: 6,
    styleName: 'ディープパープル',
    salonName: 'Tiam shibuya',
    stylistName: 'AYANE',
    colorCategoryId: 5,
    before: '17トーン',
    after: '6トーン',
    colorRecipe: 'e:V/3+P/6+e:Purple 5:1:1 3%',
    description: '褪色しオレンジ残留のあった状態から一気にバイオレットへ。エフェクトラインの発色で黄ばみの残りは一切なく染まりました。',
    remark: '',
    instagramUrl: 'https://www.instagram.com/afinar_achaco/',
  },
  {
    id: 7,
    styleName: 'カシスレッド',
    colorCategoryId: 4,
    salonName: 'Afinar YOKOHAMA',
    before: '根本中間8トーン 毛先10トーン',
    after: '根本中間6トーン 毛先8トーン',
    colorRecipe: 'R/6+e:Red  3:1  3%等倍',
    description: '元々暖色系を続けているベースなのでそれを活かしつつ、エフェクト使用して毛先まできっかり発色するようにした。',
    stylistName: 'NANA',
    instagramUrl: 'https://www.instagram.com/nana_afinar?igsh=cmc4ZTNuY3JsOGRn&utm_source=qr',
  },
  {
    id: 8,
    styleName: 'スモーキーベージュ',
    colorCategoryId: 2,
    salonName: 'limit by Afinar 大宮店',
    before: '全体18トーン',
    after: '10トーン',
    colorRecipe: '全体 FBe/10+PBe/8+V/8  5:1:10% 3%等倍<br />毛先とヘムライト部分 ek:Kuro+N/6  1:1 3%等倍',
    description: 'インナーカラーの履歴と残留があったので、ブリーチを塗り分け18レベルまでトーンアップさせ、透明感と柔らかさのあるフォギーベージュをメインにワンタッチでカラーしました。<br />毛先の裾カラーと顔まわりのヘムライトでトレンド感を♪',
    stylistName: 'YOHEI',
    instagramUrl: 'https://www.instagram.com/yohei.w1210/',
  },
  {
    id: 9,
    styleName: 'ミルクティーベージュ',
    colorCategoryId: 2,
    salonName: "mina by ONE's 町田",
    before: '17トーン',
    after: '14トーン',
    colorRecipe: '全体 N/8+Be/8+P/8  2:1:10％ 3％2.5倍<br />毛先 Yellow  3％等倍',
    description: '残留を馴染ませるためにベージュもしっかり入れて、ピンクで柔らかさを出しました。<br />毛先は高彩度のイエロー単品。色落ちがブロンドに戻るのでお気に入りです！',
    stylistName: 'MANATO',
    instagramUrl: 'https://www.instagram.com/manato_ones_mina?igsh=M204cTdlOTBhamZv&utm_source=qr',
  },
  {
    id: 10,
    styleName: 'ホワイトベージュ',
    colorCategoryId: 2,
    salonName: "ONE's MIRROR 原宿",
    before: '18トーン',
    after: '18トーン',
    colorRecipe: '表面 CL+Be/8+Mt/8+P/12 1:20%:10%:5% 3%等倍<br />毛先 P/8+V/8+e:Pink 4:1:10% CL2倍 3%等倍',
    description: '全体をホワイトベージュにした後に毛先にデザインでピンクを入れました。<br />毛先のデザインは残留せずに落ちるようにクリアで薄めつつ、発色の良いエフェクトピンクを入れることで流れづらい選定にしました。',
    stylistName: 'kazu',
    instagramUrl: 'https://www.instagram.com/ones_kazu?igsh=djl3bXQ1cTQ3c2tx',
  },
  {
    id: 11,
    styleName: 'グレージュ',
    colorCategoryId: 6,
    salonName: "mina by ONE's 原宿",
    before: '根本5トーン 毛先18.5トーン',
    after: '12トーン',
    colorRecipe: '根本、全体 Mt/10+P/10+N/8 3:1:1  4.5%<br />ポイント Yellow 3%,Green 3%',
    description: '前回のブラウン残留があったので10トーンで削りつつ少しトーンダウンして落ち着かせました<br />はちまわりにスライシングで2種類のデザインを入れています<br />トエルの前処理で高彩度のデザインも色の定着が良いです',
    stylistName: '佐藤倫也',
    instagramUrl: 'https://www.instagram.com/ones_romand_mina?igsh=MWVtbjBzN3Iyc3dldA%3D%3D&utm_source=qr',
  },
  {
    id: 12,
    styleName: 'プラチナグレー',
    colorCategoryId: 6,
    salonName: "Refine by Afinar 本厚木店",
    before: '全体18トーン',
    after: '12トーン',
    colorRecipe: 'FBe/6+V/8+P/6+8PBe<br />(1:20%:10%:10%)+CL3倍 3%等倍',
    description: 'beforeのブリーチベースのムラを直して全体を18トーンに揃える<br />黄色を消すバイオレットと柔らかさのピンクの量が大事',
    stylistName: 'HIROKI',
    instagramUrl: 'https://www.instagram.com/hiro_refine?igsh=eDE3Y2h5ZW80eWFk&utm_source=qr',
  },
  {
    id: 13,
    styleName: 'スモーキーベージュ',
    colorCategoryId: 2,
    salonName: "Luxo by Afinar 町田店",
    before: '根元5トーン、毛先7トーン',
    after: '10トーン',
    colorRecipe: 'N/10+Mt/10+M/10+V/6  2:1:1:10% 3%等倍',
    description: 'beforeの毛先に赤みが強く出ていたので、モノトーンとマットで優しく打ち消しました。<br />バイオレットも混ぜながら、スモーキーなベージュの中にも柔らかさを感じられるように作りました。',
    stylistName: 'ASUKA',
    instagramUrl: 'https://www.instagram.com/asuka_afinar?igsh=MXNicGdwdHpmc2UyNA%3D%3D&utm_source=qr',
  },
  {
    id: 14,
    styleName: 'ミントブルー',
    colorCategoryId: 7,
    salonName: "mina by ONE's 町田 kyougoku",
    before: '根本18トーン、毛先19トーン',
    after: '15トーン',
    colorRecipe: 'Green  3% 10倍',
    description: 'アンダーがすでに抜けてる状態だっだめシンプル選定。<br />Greenで作ると緑っぽい青っぽい中間の色が作れる。<br />淡い色を表現をした。',
    stylistName: '大澤麗',
    instagramUrl: 'https://www.instagram.com/rei_oosawa3/',
  },
  {
    id: 15,
    styleName: 'ミルキーベージュ',
    colorCategoryId: 2,
    salonName: 'LiL溝の口',
    before: '18.5トーン 毛先18トーン',
    after: '12トーン',
    colorRecipe: 'ベージュ→ N/12+V/12+P/12  1:1:10%  3% 5倍<br />アイスブルー→ A/6+e:Cy/6+V/8  (2:1:10%)+CL5倍  3%等倍<br />オレンジ→e:Orange+P/8  (3:1)+CL1倍 3%等倍<br />ブラウン→BBe/10+e:Orange+P/8 (1:5%:10%)+CL50% 3%等倍',
    description: 'beforeの毛先に残留が残っていたので、目立たせないようデザインカラーでムラが見えないようにした。<br />ブラウンをデザインで入れることによって全体のムラの見え方が軽減する。',
    stylistName: 'MOMO',
    instagramUrl: 'https://www.instagram.com/lil_momo.02/',
  },
  {
    id: 17,
    styleName: 'ブラック&ペールピンク',
    colorCategoryId: 4,
    salonName: 'Afinar上大岡店',
    before: '表面18トーン、インナー4トーン',
    after: '表面16トーン、インナー2トーン',
    colorRecipe: '表面根元 P/8+Mt/8  (1:2)+CL3倍  3% 1.5倍<br />表面中間毛先 P/8+Mt/8+e:Pink  (1:1:5%)+CL3倍 3% 等倍<br />インナー ek:Kuro 3% 等倍',
    description: '色落ちがブロンドに戻るようピンク味強すぎずくすみピンクで調整。<br />ブロンドに戻ってもデザインは残るようインナーはしっかり色入れした。',
    stylistName: 'Saya',
    instagramUrl: 'https://www.instagram.com/_saya38hair?igsh=NGpjeWtuaG80Nzht&utm_source=qr',
  },
  {
    id: 18,
    styleName: 'アクアブルー&パープル',
    colorCategoryId: 1,
    salonName: 'Afinar YOKOHAMA',
    before: '18.5トーン',
    after: '18.5トーン',
    colorRecipe: 'トップ e:Cy/6+Blue+P/6  (1:10％:5％)+CL5倍  3% 等倍<br />襟足 V/8+e:Purple+CL  1+10%+1 3% 等倍<br />顔周りデザイン ek:Kuro 3%',
    description: 'beforeから綺麗なペールトーンだったので最大限それを活かしてカラー。<br />個性的なモデルさんの特徴を最大限引き出してデザインした。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 20,
    styleName: 'ペールピンク',
    colorCategoryId: 4,
    salonName: 'Afinar YOKOHAMA',
    before: '17トーン',
    after: '15トーン',
    colorRecipe: 'P/8+e:Pink+Mt/10+CL  1:10%:10%:1 3%',
    description: '17トーンのベースにオレンジ残留があった為、e:Pinkの色合いで補正。<br />ピンクぶりしすぎないようにMtで補正しつつ可愛く仕上げた。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 19,
    styleName: 'シャドールーツ',
    colorCategoryId: 6,
    salonName: "ONE's ly 本厚木",
    before: '根本地毛、毛先19トーン',
    after: '根本4.5トーン、毛先17トーン',
    colorRecipe: '根本 N/3+N/6  1:1 3%等倍<br />毛先 Be/6:CL  1:8 3%等倍',
    description: '根本が伸びても気にならないようにシャドールーツのデザインを入れ、毛先は明るさを活かしてなじませました。',
    stylistName: 'Yuto',
    instagramUrl: 'https://www.instagram.com/ones_yuto_biyobiyo?igsh=MTVxd2lxNXcyN3pyMQ%3D%3D&utm_source=qr',
  },
  {
    id: 21,
    styleName: 'ハニーピンクベージュ',
    colorCategoryId: 2,
    salonName: "mina by ONE's 町田 kyougoku",
    before: '根本8トーン、毛先11トーン',
    after: '11トーン',
    colorRecipe: 'N/10+P/10+Mt/10  (3:2:1)+e:Orange5% 2%等倍',
    description: '毛先に暗めの残留が残っていたので、10レベルのカラー剤を使い残留ティントを除去しながらオンカラー。オレンジを補正に使うことにより、柔らかいミルクティーを表現しました。',
    stylistName: '戸田竜斗',
    instagramUrl: 'https://www.instagram.com/ones_ryuto.toda_mina/',
  },
  {
    id: 22,
    styleName: 'シフォンベージュ',
    colorCategoryId: 2,
    salonName: "Afinar yokohama",
    before: '全体12トーン',
    after: '7トーン',
    colorRecipe: 'Fbe/8+Pbe/6 1:1 4.5%等倍',
    description: '夏のダメージで12トーンまで褪色したベースを秋色に。柔らかいベージュ表現をしたかったのでFbeにPbeをミックス。柔らかさに深み、透かすと透明感を出せるカラーに。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 23,
    styleName: 'ダークベージュ',
    colorCategoryId: 2,
    salonName: "Afinar",
    before: '16トーン',
    after: '6トーン',
    colorRecipe: 'Fbe/6+A/6+e:Mt/3+Blue 2:1:10% 10% 4.5%',
    description: 'beforeが16トーンのピンクからの転換。赤みのティントを補色をぶつけ合いながら中和、秋らしいダークベージュに転換した。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 24,
    styleName: 'ペールピンク',
    colorCategoryId: 4,
    salonName: "Afinar",
    before: '18トーン',
    after: '14トーン',
    colorRecipe: 'P/8+Mt/8+e:Pink+CL 3:1:10%:5 3%等倍',
    description: '黒染め毛をブリーチ、残った残留に対してアプローチ。残留を生かす意味合いでピンクを選定。ムラの補正と色調調整でMtを追加。しっかりしたペールピンクに。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 25,
    styleName: 'ペールホワイトブロンド',
    colorCategoryId: 8,
    salonName: "Afinar",
    before: '18トーン',
    after: '18.5トーン',
    colorRecipe: 'V/10+P/10+CL+シャンプー 1:1:10:3%等倍+30cc',
    description: '紫のデザインカラーから転換。19トーンまでブリーチし、シャンプーをミックスしトナーカラーでハイトーンのブロンドに。ギャル感を活かしたレシピ',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
  {
    id: 26,
    styleName: 'モカベージュ',
    colorCategoryId: 2,
    salonName: "Afinar",
    before: '15トーン',
    after: '10トーン',
    colorRecipe: 'Fbe/10+Fbe/8+Pbe/10+Pbe/8 1:1:1:1 4.5%等倍',
    description: 'beforeのブリーチベースに対して目的色としてベージュ、補色でピンクとフォギーをぶつけて調整、柔らかく仕上げた。',
    stylistName: 'HIRO',
    instagramUrl: 'https://www.instagram.com/hiro_afinar/',
  },
];

export default colorRecipes;
