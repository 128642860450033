import React from 'react';
import { useParams } from 'react-router-dom';
import ColorRecipeDetailComponent from '../ui/colorComponent/ColorRecipeDetailComponent.tsx';
import { FooterBox } from '../ui/component/FooterBox.tsx'
import { Header } from '../ui/component/Header.tsx';

const ColorRecipeDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <main>
      <Header />
      <ColorRecipeDetailComponent id={id ? parseInt(id) : undefined} />
      <FooterBox />
    </main>
  );
};

export default ColorRecipeDetail;
