import React from 'react'
import { CTA } from './CTA.tsx'
import { AnimatedComponentFromDown } from '../component/AnimationWrapper.tsx'
import {
  Box,
  Center,
  Text,
  Image,
} from '@chakra-ui/react'

export const TopContent = () => {
  return (
    <Box mb={[5, 5, '80px']}>
      <Image src='/img/top.jpg' width={'100%'} />
      <Box position={'relative'}>
        <Image
          src='/img/txbg.jpg'
          width={'100%'}
          className='spDisplayNone'
        />
        <Image
          src='/img/txbg_sp.jpg'
          width={'100%'}
          className='pcDisplayNone'
        />
        <Box className='top-text-wrapper'>
          <Center>
            <AnimatedComponentFromDown>
              <Text className='lora' fontSize={['lg', '2xl', '3xl', '4xl']} align={'center'} color={'white'}>
                混じりけのない個性を鮮烈に彩るtoel
                <br />
                一切の濁りを排除した鮮やかな色彩と、
                <br />
                艶を纏った光沢。
                <br />
                トエルカラーは、更なる色彩領域へ。
              </Text>
            </AnimatedComponentFromDown>
          </Center>
          <CTA />
        </Box>
      </Box>
    </Box >
  )
}
