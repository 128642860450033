import React from 'react'
import {
  Box,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';
import { AnimatedComponentFromDown } from '../component/AnimationWrapper.tsx'
import { CTA } from './CTA.tsx'

export const BrandBox = () => {

  return (
    <Box className='section'>
      <Box id='concept' className='title'>
        BRAND CONCEPT
      </Box>
      <Flex pb={[0, 0, 0, 10]} direction={['column', 'column', 'column', 'row']}>
        <AnimatedComponentFromDown>
          <Box position={'relative'} zIndex={1}>
            <Image src='/img/obj.jpg' width={'90%'} />
            <Image src='/img/md.jpg' width={'40%'}
              position={'absolute'}
              bottom={[0, -8]}
              right={[-1, -10]}
              zIndex={2} />
          </Box>
        </AnimatedComponentFromDown>
        <Flex direction={'column'} minWidth={'40%'} color={'white'}>
          <Text fontSize={'3xl'} mt={5} align={'center'} className='lora'>
            <Box as='span' color='#f7c4bd'>
              超
            </Box>
            <Box as='span' color='#acdfc9'>
              高
            </Box>
            <Box as='span' color='#bfdefb'>
              彩
            </Box>
            <Box as='span' color='#ffcf03'>
              度
            </Box>
            <br className='pcDisplayNone' />
            エフェクトカラー
          </Text>
          <Text fontSize={['md', 'xl']} mt={5} align={'center'}>
            アルカリカラーでは不可能とされていた、<br />
            美容師の希望を叶える<br />
            超高彩度エフェクトカラー。<br />
          </Text>
        </Flex >
      </Flex>
      <CTA />
    </Box >
  );
};
