import React from 'react'
import { flexRender, getCoreRowModel, useReactTable, getFilteredRowModel, PaginationState, getPaginationRowModel } from '@tanstack/react-table'
import {
  Select,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  Center
} from '@chakra-ui/react'
import { dealers } from '../../libs/DealerData.ts'
import { MdArrowDropDown } from "react-icons/md";
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const DealerTable = () => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data: dealers,
    columns: [
      { accessorKey: 'region', header: '地域', },
      { accessorKey: 'name', header: '店舗名', },
      { accessorKey: 'address', header: '住所', },
      {
        accessorKey: 'tel', header: '電話番号', cell:
          props => {
            const value = props.cell.getValue();
            return <Link href={'tel:' + value}>{value}</Link>;
          }
      },
      {
        accessorKey: 'web', header: 'web', cell:
          props => {
            const value = props.cell.getValue();
            if (value) {
              return <Link href={value} isExternal>HP<ExternalLinkIcon mx='1px' /></Link>;
            }
          }
      },
      {
        accessorKey: 'map', header: 'map', cell:
          props => {
            const value = props.cell.getValue();
            if (value) {
              return <Link href={value} isExternal>Map<ExternalLinkIcon mx='1px' /></Link>;
            }
          }
      },
    ],
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  // フィルター ドロップダウン
  const filterOptions = [
    { value: '全国広域', label: '全国広域' },
    { value: '北海道', label: '北海道' },
    { value: '東北', label: '東北' },
    { value: '関東', label: '関東' },
    { value: '中部', label: '中部' },
    { value: '近畿', label: '近畿' },
    { value: '中国・四国', label: '中国・四国' },
    { value: '九州・沖縄', label: '九州・沖縄' },
  ]

  // フィルター動作
  // 地域のみフィルタする
  const handleChange = (e) => {
    table.getHeaderGroups()[0].headers[0].column.setFilterValue(e.target.value);
  }

  return (
    <>
      <Box color={'white'}>
        <Box w={[300]} mr={'auto'} ml={'auto'}>
          <Select
            colorScheme='whiteAlpha'
            placeholder='地域で検索する'
            icon={<MdArrowDropDown />}
            border={'1px'}
            onChange={(e) => handleChange(e)}
          >
            {filterOptions.map((o, index) => <option key={index} value={o.value}>{o.label}</option>)}
          </Select>
        </Box>
        <Box mt={'30px'} mb={'30px'}>
          <TableContainer>
            <Table colorScheme='whiteAlpha' className='tb01' size={['sm', 'md']}>
              <TableCaption mt={0}>地域で検索することが可能です</TableCaption>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      if (header.id !== 'region') {
                        return (
                          <Th key={header.id} colSpan={header.colSpan}>
                            <Box>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>
                          </Th>
                        )
                      }
                      return null;
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        if (cell.id.search('region') === -1) {
                          return (
                            <Td key={cell.id} fontWeight={'bold'} > {flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                          )
                        }
                        return null;
                      }
                      )}
                    </Tr>
                  )
                })}
              </Tbody>
              <Tfoot>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id} className='head'>
                    {headerGroup.headers.map(header => {
                      if (header.id !== 'region') {
                        return (
                          <Th key={header.id} colSpan={header.colSpan}>
                            <Box>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>
                          </Th>
                        )
                      }
                      return null;
                    })}
                  </Tr>
                ))}
              </Tfoot>
            </Table>
          </TableContainer>
          <Center>
            <Button
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              size='xs'
              m={1}
              background={'white'}
              color={'black'}
              _hover={{ bg: '#bdc3c7' }}
            >
              {"<"}
            </Button>
            | Page
            {`${table.getState().pagination.pageIndex + 1
              } of ${table.getPageCount()} |`}
            <Button
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              size='xs'
              m={1}
              background={'white'}
              color={'black'}
              _hover={{ bg: '#bdc3c7' }}
            >
              {">"}
            </Button>
          </Center>
        </Box >
      </Box >
    </>
  )
}