import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Image } from '@chakra-ui/react';

type ModalWrapperProps = {
  imgPath: string;
  isOpen: boolean;
  onClose: () => void;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({ imgPath, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl' allowPinchZoom={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody display='flex' justifyContent='center' alignItems='center'>
          <Image src={imgPath} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalWrapper;