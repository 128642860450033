import React from 'react'
import { Box, Flex, Image, Button, Link, Center } from '@chakra-ui/react'

export const ColorRecipeBox = () => {
  return (
    <Box className='section'>
      <Box id='color-recipe' className='title' style={{ position: 'relative' }}>
        COLOR RECIPE<span className='new-label'>NEW</span>
      </Box>
      <Flex
        direction={['column', 'column', 'row', 'row']}
      >
        <Box flex="1" mb={4}>
          <Link href='./color-recipe'>
            <Image
              src='/img/color_recipe_full_color_bg.png'
            />
          </Link>
        </Box>
      </Flex>
      <Center>
        <Button
          size={'lg'}
          background={'white'}
          color={'black'}
          _hover={{ bg: '#bdc3c7' }}
          className='lora'
          mt={[4, 10]}
        >
          <Link href='./color-recipe'>
            レシピを見る
          </Link>
        </Button>
      </Center>
    </Box>
  )
}
