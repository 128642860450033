import React from 'react'
import { CTA } from './CTA.tsx'
import { Box, Flex, Image } from '@chakra-ui/react'

type Props = {
    setIsOpen: any
    setImgPath: any
}

export const ColorChartBox = (props: Props) => {
    const imgClickHandler = React.useCallback(
        (e) => {
            props.setImgPath(e.target.src)
            props.setIsOpen(true)
        },
        [props]
    )
    return (
        <Box className='section'>
            <Box id='colorchart' className='title'>
                COLOR CHART
            </Box>
            <Flex justify={'space-evenly'}>
                <Image
                    onClick={(e) => imgClickHandler(e)}
                    src='/img/chart_1.jpg'
                    maxW={'48%'}
                />
                <Image
                    onClick={(e) => imgClickHandler(e)}
                    src='/img/chart_2.jpg'
                    maxW={'48%'}
                />
            </Flex>
            <CTA />
        </Box>
    )
}