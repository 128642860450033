import React from 'react'
import { Box, Flex, Link, Image, Center, Text } from '@chakra-ui/react'

export const FooterBox = () => {
  return (
    <Box mx="auto" pt={100} pb={5}>
      <Center mb={5} >
        <Text fontSize={['xs', 'md']}>
          全ての画像の無断転載・無断引用は禁止です。
        </Text>
      </Center>
      <Flex justify={'center'} align={'center'} gap={2}>
        <Link
          href='https://www.instagram.com/toel_official/?hl=ja'
          isExternal
        >
          <Image src='/img/ig.png' boxSize={30} />
        </Link>
        <Link
          href='https://www.instagram.com/toel_official/?hl=ja'
          isExternal
        >
          <Text>FOLLOW US</Text>
        </Link>
      </Flex>
      <Center mt={5} >
        <Text fontSize={['xs', 'md']}>
          Copyright InterCosme Co., Ltd. All Rights Reserved
        </Text>
      </Center>
    </Box>
  )
}
