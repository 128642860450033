import React from 'react'
import { Box } from '@chakra-ui/react';
import { FeatureImage } from './FeatureImage.tsx'

export const FeatureBox = () => {
    return (
        <Box className='section'>
            <Box id='feature' className='title'>
                FEATURE
            </Box>
            <Box className='featureWrapper'>
                <FeatureImage n={0} />
                <FeatureImage n={1} />
                <FeatureImage n={2} />
            </Box>
        </Box>
    )
}