import React from 'react'
import { Box } from '@chakra-ui/react';
import { LineUpImage } from './LineUpImage.tsx'

export const LineUpBoxSp = () => {
    return (
        <Box className='section pcDisplayNone spLineup'>
            <Box id='lineupsp' className='title'>LINE UP</Box>
            <LineUpImage n={0} />
            <LineUpImage n={1} />
            <LineUpImage n={2} />
            <LineUpImage n={3} />
        </Box>
    )
}