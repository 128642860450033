import React from 'react';
import { slide as Menu } from 'react-burger-menu';

export const Burger = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleLinkClick = () => {
    // リンクがクリックされたときの処理
    setIsOpen(false);
  };

  const handleMenuStateChange = (state) => {
    // メニューの状態が変更されたときの処理
    setIsOpen(state.isOpen);
  };

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={handleMenuStateChange}
      right
    >
      <div id='page-wrap'>
        <div id='menu-wrap'>
          <a href='#newrelease' onClick={handleLinkClick}>
            NEW RELEASE
          </a>
          <a href='#concept' onClick={handleLinkClick}>
            CONCEPT
          </a>
          <a href='#feature' onClick={handleLinkClick}>
            FEATURE
          </a>
          <a href='#lineupsp' onClick={handleLinkClick}>
            LINE UP
          </a>
          <a href='#color-recipe' onClick={handleLinkClick} style={{ position: 'relative' }}>
            COLOR RECIPE <span className='new-label'>NEW</span>
          </a>
          <a href='#colorchart' onClick={handleLinkClick}>
            COLOR CHART
          </a>
          <a href='#videosp' onClick={handleLinkClick}>
            VIDEO
          </a>
          <a href='#interview' onClick={handleLinkClick}>
            INTERVIEW
          </a>
          <a href='#dealers' onClick={handleLinkClick}>
            DEALERS
          </a>
        </div>
      </div>
    </Menu>
  );
};
