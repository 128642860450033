import { extendTheme } from '@chakra-ui/react'

const customColors = {
  brown: {
    50: '#fdf8f6',
    100: '#f2e8e5',
    200: '#ebd4cd',
    300: '#d5aa9c',
    400: '#b07a6c',
    500: '#8a5a4b',
    600: '#6f483d',
    700: '#53362e',
    800: '#38241e',
    900: '#1c120f',
  },
  silver: {
    50: '#f8f8f8',
    100: '#f0f0f0',
    200: '#e0e0e0',
    300: '#d0d0d0',
    400: '#c0c0c0',
    500: '#a0a0a0',
    600: '#808080',
    700: '#606060',
    800: '#404040',
    900: '#202020',
  },
};

const theme = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    body: `'Noto Sans JP', sans-serif`,
  },
  colors: customColors,
}

export default extendTheme(theme)
