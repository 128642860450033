import React from "react";
import { VStack, Button, Text, Box, useBreakpointValue } from "@chakra-ui/react";
import { ColorCategory } from "../../libs/ColorCategories.ts";

interface SidebarProps {
  colorCategories: ColorCategory[];
  setSelectedColorCategoryId: (colorCategoryId: number | null) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ colorCategories, setSelectedColorCategoryId }) => {
  // Determine if the sidebar should be displayed based on screen size
  const isSidebarVisible = useBreakpointValue({ base: false, md: true });

  // If sidebar is not visible, return null
  if (!isSidebarVisible) {
    return null;
  }

  return (
    <VStack align="stretch" spacing={4} w={350} p={10} mt={160}>
      <Button
        onClick={() => setSelectedColorCategoryId(null)}
        backgroundColor="white"
        color="black"
        variant="unstyled"
        _hover={{ backgroundColor: "gray.200" }}
        mb={4}
      >
        RESET すべて表示する
      </Button>
      <Box position="relative" pb={2}>
        <Text fontWeight="bold">カラーから選ぶ</Text>
        <Box
          as="span"
          position="absolute"
          left={0}
          right={0}
          bottom={1}
          height="2px"
          backgroundColor="white"
        />
      </Box>
      {colorCategories.map(category => (
        <Button
          key={category.name}
          onClick={() => setSelectedColorCategoryId(category.id)}
          color={category.name === "ネイビー" ? "lightblue" : category.color} // Conditional text color for navy
          borderColor={category.color}
          borderWidth={2}
          variant="outline"
          size="sm"
        >
          {category.name}
        </Button>
      ))}
    </VStack>
  );
};

export default Sidebar;
