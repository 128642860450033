import React from 'react'
import {
  Box,
  Text,
  Image,
  Flex
} from '@chakra-ui/react';

export const NewRelease = () => {

  return (
    <Box className='section'>
      <Box id='newrelease' className='title'>
        NEW RELEASE
      </Box>
      <Box position={'relative'} mb={10}>
        <Image
          src="/img/beige_model.jpg"
          w={'100%'}
          mb={4}
        />
        <Flex
          direction={['column', 'column', 'row', 'row']}
        >
          <Box flex="1" pr={[0, 10]} mt={[0, 10]}>
            <Text fontSize={['lg', '2xl']} mb={4}>
              柔らかな質感に多彩なニュアンスを掛け合わせたツヤ感たっぷりの<Box as='span' color='#d2b48c'>ベージュ</Box>カラーが新登場。
            </Text>
            <Text fontSize='md' mb={2}>
              ●FBe/フォギーベージュ<br />
              スモーキーなくすみ感に、ベージュのまろやかさを加えた色味<br />
            </Text>
            <Text fontSize='md' mb={2}>
              ●PBe/ピンクベージュ<br />
              上品なピンクにベージュの軽やかさとくすみ感を加えた色味<br />
            </Text>
            <Text fontSize='md' mb={4}>
              ●BBe/ブラウンベージュ<br />
              温かみのあるナチュラルブラウンに、ベージュの柔らかさと透明感を加えた色味<br />
            </Text>
            <Text fontSize='md' mb={2}>
              2024年5月末発売
            </Text>
          </Box>
          <Box flex="1">
            <Image
              src='/img/beige_chart.jpg'
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
